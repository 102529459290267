<!--
  Serves as the default market home page and displays the market in a variety of modes:
  0: Series - shows each series on the market
  1: Primary - shows all primary types on the market
  2: Variant - shows every variant on the market that's marked as active and featured
-->

<template>
  <div class="market-container full">
    <div class="market-header">
      <Typography variant="pc-title-48 desktop-nav-only">Market</Typography>
      <div class="market-header__display">
        <p>Display</p>
        <div class="market-header__display__options">
          <GridOneIcon
            color="white"
            role="button"
            :class="{ active: displayMode === 0 }"
            @click.native="changeDisplayMode(0)"
          />
          <GridFourIcon
            color="white"
            role="button"
            :class="{ active: displayMode === 1 }"
            @click.native="changeDisplayMode(1)"
          />
          <GridSixteenIcon
            color="white"
            role="button"
            :class="{ active: displayMode === 2 }"
            @click.native="changeDisplayMode(2)"
          />
        </div>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <!-- Series gallery -->
      <div
        v-if="displayMode === 0 && seriesFormatted"
        class="standard-gallery staggered-gallery"
        :data-number="seriesFormatted.length"
        key="mode-0"
      >
        <router-link
          v-for="(series, index) in seriesFormatted"
          :key="series.id"
          :to="`/market/${series.defaultType.tokenAddress}/${series.defaultType.typePrimary.id}/all`"
        >
          <TokenCard
            :tokenData="series"
            square
            staggered
            :index="index"
            mode="series"
          />
        </router-link>
      </div>

      <!-- Primary types gallery -->
      <div
        v-if="displayMode === 1"
        class="standard-gallery staggered-gallery"
        :data-number="primaryTypesFormatted.length"
        key="mode-1"
      >
        <router-link
          v-for="(value, index) in primaryTypesFormatted"
          :key="`type-${index}`"
          :to="`/market/${value.tokenAddress}/${value.typePrimary.id}/all`"
        >
          <TokenCard
            :tokenData="value"
            mode="market-primary"
            square
            staggered
            :index="index"
          />
        </router-link>
      </div>

      <!-- Variants gallery -->
      <div
        v-if="displayMode === 2"
        class="standard-gallery staggered-gallery"
        :data-number="market.length"
        key="mode-2"
      >
        <router-link
          v-for="(type, index) in market"
          :key="`token-${index}`"
          :to="`/market/${type.tokenAddress}/${type.typePrimary.id}/${type.typeSecondary.id}`"
        >
          <TokenCard
            :tokenData="type"
            square
            staggered
            :index="index"
            mode="market"
          />
        </router-link>
      </div>
    </transition>
    <transition name="fade">
      <LoaderCover v-if="loadingMarket" fixed transparent center />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'MarketHome',
  async created () {
    // Set the gallery display mode from URL params if applicable
    if (this.$route.query.displayMode) {
      this.displayMode = parseInt(this.$route.query.displayMode)
    }
    await this.getTokenContracts()
    await this.formatMarket()
    this.loadingMarket = false
    await this.loadAllSeries()
  },
  data () {
    return {
      loadingMarket: true,
      market: [],
      marketPrimary: [],
      displayMode: 1
    }
  },
  computed: {
    ...mapGetters([
      'marketData',
      'globalSettings',
      'tokenContracts',
      'allSeries',
      'primaryTypes'
    ]),

    seriesFormatted () {
      if (!this.market) return null
      const seriesFormatted = []
      this.allSeries.forEach((series) => {
        const formattedData = {
          id: series.id,
          title: series.name,
          image: series.image,
          collectionGroup: series.collectionGroup
        }
        // This is a bit hacky, but essentially it sets the route link for the series card
        // to the first primary type item for each series
        this.market.forEach((type) => {
          if (type.series.id === series.id && !formattedData.defaultType) {
            formattedData.defaultType = type
          }
        })
        if (formattedData.defaultType) {
          seriesFormatted.push(formattedData)
        }
      })
      return seriesFormatted
    },

    primaryTypesFormatted () {
      const alreadyAdded = []
      const formatted = []
      if (!this.market) return formatted
      this.market.forEach((type) => {
        if (!alreadyAdded.includes(type.typePrimary.id)) {
          alreadyAdded.push(type.typePrimary.id)
          formatted.push({
            id: type.id,
            tokenType: type.id,
            title: `${type.series && type.series.name !== type.typePrimary.name ? `${type.series.name} - ` : ''}${type.typePrimary.name}`,
            collectionGroup: type.collectionGroup,
            tokenAddress: type.tokenAddress,
            typePrimary: type.typePrimary
          })
        }
      })
      return formatted
    }
  },
  methods: {
    ...mapMutations([
      'setGlobalState'
    ]),
    ...mapActions([
      'getTokenContracts',
      'getTargetTypes',
      'loadAllSeries'
    ]),

    async formatMarket () {
      for (let i = 0; i < this.tokenContracts.length; i++) {
        const contract = this.tokenContracts[i]
        const types = await this.getTargetTypes({ contract: contract.id })
        if (types) {
          types.forEach((type) => {
            const dataFormatted = type
            dataFormatted.collectionGroup = contract.collectionGroup
            dataFormatted.title = dataFormatted.name
            this.market.push(dataFormatted)
          })
        }
      }
    },

    changeDisplayMode (modeIndex) {
      this.displayMode = modeIndex
      this.$router.push({
        path: 'market',
        query: { displayMode: modeIndex }
      })
    }
  }
}
</script>

<style lang="scss">
.market-container.full {
  .standard-gallery > a {
    text-decoration: none;
  }
}
.market-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__display {
    p {
      display: inline-block;
      margin-right: $space-s;
    }

    &__options {
      display: inline-block;
      svg {
        cursor: pointer;
        margin-right: 8px;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}
</style>
